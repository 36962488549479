import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import useCancellablePromise from 'hooks/useCancellablePromise';
import {
  EMPLOYEE_PATHS, PATH_NAMES, PEOPLE, PATHS,
} from 'utils/constants';
import {
  SELF, SPOUSE, DEPENDENT, headingMap,
} from 'components/employeePage/consts';
import { ArchivedSearchBanner } from 'components/employeePage/ArchivedBanner';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import { SearchFormHeading, UpdateSearchText } from 'components/monitoringForm/formHeadings';
import SubmitButton from '../submitButton';
import {
  Tab, TabSet, TabPanel, TabContent,
} from '../tabPanel';
import SourceSelectionWrapper from '../sourceSelection/formikSourceSelectionWrapper';
import { StrictLastName } from './strictLastName';
import { getFilterCount } from '../entityForm/entityForm';
import StateDropdownWrapper from './formikStateDropdownWrapper';
import {
  firstName,
  lastName,
  strictLastName,
  altFirsts,
  altLasts,
  zipcodes,
  states,
  employers,
  excludedAddresses,
  excludedEmployers,
  excludedOccupations,
  geoFiltersHeading,
  addressFieldToolTip,
  employerFiltersHeading,
  excludeEmployerToolTip,
  excludeOccupationToolTip,
  disabledNameTooltip,
  employerHelpText,
  validationSchema,
} from './consts';
import AltNickNames from './nicknames';
import ZipcodeFields from './zipcodes';


function PersonSearchForm({
  edit,
  sources,
  initForm,
  searchType,
  employeeId,
  searchId,
  submitSearch,
  toggleConfirmDelete,
  archived,
}) {
  const history = useHistory();
  const { cancellablePromise } = useCancellablePromise();

  const handleSubmit = (values, actions) => {
    const sourcesLength = Array.from(values.sources).length;
    if (sourcesLength < 1) {
      toast('Please select at least one source.');
      actions.setSubmitting(false);
      return;
    }
    actions.setSubmitting(true);
    const searchData = {
      ...values,
      search_type: searchType,
      all_sources: sourcesLength === sources.length,
    };
    cancellablePromise(submitSearch(employeeId, searchData)).then(({ data }) => {
      actions.setSubmitting(false);
      history.push(`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${employeeId}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}/${data.id}`);
    }).catch((err) => {
      if (!err.isCanceled) {
        actions.setSubmitting(false);
        const errors = err && err.response && err.response.data;
        let errorMessage = 'Something went wrong.';
        if (Array.isArray(errors)) {
          // max persons error
          errorMessage = errors[0];
        } else if (errors) {
          errorMessage = 'Some fields had errors';
          actions.setErrors(errors);
        }
        toast(errorMessage);
        throw err;
      }
    });
  };


  const disableNameFields = searchType === SELF;

  return (
    <>
      { archived && (
      <ArchivedSearchBanner />
      )}
      <div className="form-wrapper search-form employee-form">
        <Formik
          initialValues={({ ...initForm })}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <div>
              {
                edit
                  ? (
                    <>
                      <SearchFormHeading
                        headerType={headingMap[searchType]}
                        hideDelete={searchType === SELF}
                        closeUrl={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${employeeId}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`}
                        toggleConfirmDelete={() => toggleConfirmDelete(searchId)}
                        editing
                      />
                      <UpdateSearchText searchType={"search's filters"} />
                    </>
                  )
                  : (
                    <SearchFormHeading
                      headerType={headingMap[searchType]}
                      closeUrl={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${employeeId}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`}
                    />
                  )
              }
              <Form autoComplete="off" noValidate>
                <fieldset disabled={archived}>
                  <div className="row search-name-row">
                    <div className="col">
                      <SchemaField
                        name={firstName}
                        id={firstName}
                        label="First name"
                        fieldType="TextField"
                        className="form-input"
                        required
                        disabled={disableNameFields}
                        tooltip={disableNameFields ? disabledNameTooltip : null}
                      />
                    </div>
                    <div className="col" style={{ position: 'relative' }}>
                      <div className="last-name-wrapper">
                        <SchemaField
                          name={lastName}
                          id={lastName}
                          label="Last name"
                          fieldType="TextField"
                          className="form-input"
                          required
                          disabled={disableNameFields}
                          tooltip={disableNameFields ? disabledNameTooltip : null}
                        />
                        <StrictLastName
                          name={strictLastName}
                          id={strictLastName}
                        />
                      </div>
                    </div>
                    <div className="col-no-flex">
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        edit={edit}
                        type={headingMap[searchType]}
                        icon=""
                      />
                    </div>
                  </div>
                </fieldset>

                <hr />
                <TabPanel
                  render={({ activeTab, setActiveTab }) => (
                    <div>
                      <TabSet
                        onTabClick={setActiveTab}
                      >
                        <Tab
                          tabKey={0}
                          activeTab={activeTab}
                          badge={getFilterCount([
                            ...values[zipcodes],
                            ...values[states],
                            ...values[excludedAddresses],
                          ])}
                        >
                          Geographic Filters
                        </Tab>
                        <Tab
                          tabKey={1}
                          activeTab={activeTab}
                          badge={getFilterCount([
                            ...values[altFirsts],
                            ...values[altLasts],
                          ])}
                        >
                          Alternate Names
                        </Tab>
                        <Tab
                          tabKey={2}
                          activeTab={activeTab}
                          badge={getFilterCount([
                            ...values[employers],
                            ...values[excludedEmployers],
                            ...values[excludedOccupations],
                          ])}
                        >
                          Employer / Occupation Filters
                        </Tab>
                        <Tab
                          tabKey={3}
                          activeTab={activeTab}
                          badge={values.sources.size}
                        >
                          Sources
                        </Tab>
                      </TabSet>

                      <TabContent
                        tabKey={0}
                        activeTab={activeTab}
                      >
                        <fieldset disabled={archived}>
                          <div>
                            <p>{geoFiltersHeading}</p>
                            <div className="row">
                              <div className="col">
                                <div className="focusable">
                                  <div className="input-title">
                                    U.S. States
                                  </div>
                                  <StateDropdownWrapper
                                    field={states}
                                    selected={values[states]}
                                    disabled={archived}
                                  />
                                </div>
                                <SchemaField
                                  name={excludedAddresses}
                                  id={excludedAddresses}
                                  label="exclude addresses"
                                  addButtonText="address to exclude"
                                  className="form-input"
                                  limit={20}
                                  fieldType="ArrayField"
                                  minFields={1}
                                  infoText={addressFieldToolTip}
                                  limitReachedText="exclude address filters"
                                />
                              </div>
                              <div className="col">
                                <ZipcodeFields />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </TabContent>

                      <TabContent
                        tabKey={1}
                        activeTab={activeTab}
                      >
                        <fieldset disabled={archived}>
                          <div>
                            <div className="row">
                              <div className="col">
                                <AltNickNames
                                  firstName={values[firstName]}
                                  value={values[altFirsts]}
                                />
                              </div>
                              <div className="col">
                                <SchemaField
                                  name={altLasts}
                                  id={altLasts}
                                  label="alternate last name"
                                  addButtonText="alternate last name"
                                  className="form-input"
                                  limit={3}
                                  fieldType="ArrayField"
                                  minFields={1}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </TabContent>

                      <TabContent
                        tabKey={2}
                        activeTab={activeTab}
                      >
                        <fieldset disabled={archived}>
                          <div>
                            <p>{employerFiltersHeading}</p>
                            <div className="row">
                              <div className="col">
                                <div className="focusable">
                                  <SchemaField
                                    name={employers}
                                    id={employers}
                                    label="employer"
                                    className="form-input"
                                    limit={60}
                                    fieldType="ArrayField"
                                    minFields={1}
                                    addButtonText="employer"
                                    limitReachedText="employer filters"
                                  />
                                  <div className="helptext">
                                    {employerHelpText}
                                  </div>
                                </div>
                                <div className="focusable">
                                  <SchemaField
                                    name={excludedOccupations}
                                    id={excludedOccupations}
                                    label="exclude occupation"
                                    className="form-input"
                                    limit={20}
                                    fieldType="ArrayField"
                                    minFields={1}
                                    addButtonText="occupation to exclude"
                                    infoText={excludeOccupationToolTip}
                                    limitReachedText="exclude occupation filters"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="focusable">
                                  <SchemaField
                                    name={excludedEmployers}
                                    id={excludedEmployers}
                                    label="exclude employer"
                                    addButtonText="employer to exclude"
                                    className="form-input"
                                    limit={20}
                                    fieldType="ArrayField"
                                    minFields={1}
                                    infoText={excludeEmployerToolTip}
                                    limitReachedText="exclude employer filters"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </TabContent>

                      <TabContent
                        tabKey={3}
                        activeTab={activeTab}
                      >
                        <fieldset disabled={archived}>
                          <div className="source-selection">
                            <SourceSelectionWrapper
                              sources={sources}
                              selected={values.sources}
                              field="sources"
                            />
                          </div>
                        </fieldset>
                      </TabContent>
                    </div>
                  )}
                />
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
}

PersonSearchForm.propTypes = {
  edit: PropTypes.bool.isRequired,
  sources: PropTypes.array.isRequired,
  initForm: PropTypes.object.isRequired,
  submitSearch: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  searchId: PropTypes.string,
  searchType: PropTypes.oneOf([SELF, SPOUSE, DEPENDENT]).isRequired,
  toggleConfirmDelete: PropTypes.func,
  archived: PropTypes.bool,
};

PersonSearchForm.defaultProps = {
  toggleConfirmDelete: () => {},
  searchId: null,
  archived: false,
};

export default PersonSearchForm;
