import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-virtualized/styles.css';
import {
  List, WindowScroller, AutoSizer, InfiniteLoader, CellMeasurer, CellMeasurerCache,
} from 'react-virtualized';

import { DASHBOARD_RECORD_LIMIT, SCROLLABLE_CONTAINER } from 'utils/constants';
import LoadingMore from 'components/shared/loadingMore';
import ResultRow from './recordRow';
import { createComment, setSectionStatus, clearAnimatingContributions, setBulkStatus } from '../../reducers/contributions/actions';
import { getAllContributions } from 'reducers/contributions/selectors';


class DashboardResults extends Component {
  constructor(props) {
    super(props);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.rowIsLoaded = this.rowIsLoaded.bind(this);
    this.getMoreContributions = this.getMoreContributions.bind(this);

    this.onPageResize = this.onPageResize.bind(this);
    this.startExitAnimation = this.startExitAnimation.bind(this);
    this.loading = false;

    this.endCount = Number.MAX_SAFE_INTEGER;
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      keyMapper: rowIndex => (this.props.contributions[rowIndex].meta.id),
    });
    this.exitingRecords = new Set();
  }

  componentDidMount() {
    this.props.clearAnimatingContributions();
    setTimeout((_) => {
      if (this.cache && this.list) {
        this.cache.clearAll();
        this.list.recomputeRowHeights();
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.animatingItems && this.props.animatingItems) {
      this.startExitAnimation(Object.keys(this.props.animatingItems));
    }
    // this is so there is no gap after the "loading more..." animation
    if (this.props.contributions.length !== prevProps.contributions.length) {
      const remainder = this.props.contributions.length % DASHBOARD_RECORD_LIMIT;
      const index = this.props.contributions.length - (remainder || DASHBOARD_RECORD_LIMIT);
      if (index > 0) {
        for (let i = prevProps.contributions.length - 1; i < this.props.contributions.length; i++) {
          this.cache.clear(i);
        }
      }
    }
  }

  onPageResize() {
    setTimeout((_) => {
      if (this.cache && this.list) {
        this.cache.clearAll();
        this.list.recomputeRowHeights();
      }
    }, 0);
  }

  getMoreContributions() {
    // todo: not causing issues but this request is unneccessarily made when updating a filter and fewer than DASHBOARD_RECORD_LIMIT results are returned
    if (!this.loading) {
      this.loading = true;
      this.props.getContributions(this.props.contributions.length).then((data) => {
        this.loading = false;
        if (data && data.length < DASHBOARD_RECORD_LIMIT) {
          this.endCount = this.props.contributions.length;
          if (data.length === 0 && this.list) {
            this.list.forceUpdate();
          }
        }
      }).catch((err) => {
        this.loading = false;
        throw err;
      });
    }
  }

  mapIndexToRow(index) {
    const { contributions } = this.props;
    if (contributions.length > 0) {
      const targetItem = contributions[index];
      const checked = this.props.selected.has(targetItem.meta.id);
      const sourceItem = this.props.sources.find(s => s.id === targetItem.meta.source_id);
      // todo it is ok to use person_schema for now but this may not always be the case
      if(!sourceItem) return null;
      const sourceSchema = sourceItem.person_schema;
      return {
        content: {
          data: targetItem.data,
          meta: targetItem.meta,
          sourceId: targetItem.sourceId,
          statuses: this.props.statuses,
          toggleContribution: this.props.toggleContribution,
          selected: checked,
          isLast: index === contributions.length - 1,
          setSectionStatus: this.props.setSectionStatus,
          indeterminate: false,
          createComment: this.props.createComment,
          sourceSchema,
          sourceCat: targetItem.meta.category,
          jurisdictionName: targetItem.meta.jurisdiction,
          startExitAnimation: this.startExitAnimation,
          isExiting: this.exitingRecords.has(targetItem.meta.id),
        },
      };
    }
  }

  rowRenderer({ key, index, style, parent }) {
    const targetRow = this.mapIndexToRow(index);
    if(!targetRow) return null;
    const { isExiting } = targetRow.content;
    let top = style.top;
    if (!isExiting) {
      const nextOffset = Array.from(this.exitingRecords)
        .map(contribId => this.props.contributions.findIndex(item => item.meta.id === contribId))
        .filter(rowIndex => rowIndex >= 0 && rowIndex < index)
        .reduce((acc, rowIndex) => acc + this.cache.getHeight(rowIndex), 0);
      top -= nextOffset;
    }
    const atEnd = index === this.props.contributions.length - 1;
    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div className={`row-transitor ${this.exitingRecords.size > 0 ? 'active' : ''}`} key={key} style={{ ...style, height: isExiting ? 0 : style.height, top }}>
          <ResultRow
            {...targetRow.content}
          />
          {(atEnd && (this.endCount === Number.MAX_SAFE_INTEGER)) ? (
            <LoadingMore />) : ''}
        </div>
      </CellMeasurer>
    );
  }

  rowIsLoaded({ index }) {
    const totalExpectedRows = this.props.contributions.length;
    return index < totalExpectedRows;
  }

  startExitAnimation(contribIds) {
    if (this.props.filter === null) {
      this.props.setBulkStatus(Object.values(this.props.animatingItems || {}));
      return this.props.clearAnimatingContributions();
    }
    contribIds.forEach((index) => {
      this.exitingRecords.add(parseInt(index));
    });
    this.playAnimation();
  }

  playAnimation() {
    this.forceUpdate((_) => {
      setTimeout((_) => {
        this.exitingRecords.clear();
        this.props.setBulkStatus(Object.values(this.props.animatingItems || {}));
        this.props.clearAnimatingContributions();
        this.forceUpdate();
      }, 500);
    });
  }

  render() {
    if (this.props.contributions.length === 0) {
      return (
        this.props.noResultsDashboard
      );
    }

    const rowCount = this.props.contributions.length;

    return (
      <WindowScroller
        scrollElement={SCROLLABLE_CONTAINER}
        onResize={this.onPageResize}
      >
        {
          ({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (
            <AutoSizer disableHeight>
              {
                ({ width }) => (
                  <div
                    className="dashboard-results-container"
                    ref={(el) => {
                      registerChild(el);
                    }}
                    style={{ width }}
                  >
                    <InfiniteLoader
                      isRowLoaded={this.rowIsLoaded}
                      loadMoreRows={this.getMoreContributions}
                      rowCount={this.endCount}
                    >
                      {
                        ({ onRowsRendered, registerChild }) => (
                          <List
                            ref={(el) => {
                              registerChild(el);
                              this.list = el;
                            }}
                            autoHeight
                            height={height}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            rowCount={rowCount}
                            rowRenderer={this.rowRenderer}
                            scrollTop={scrollTop}
                            width={width}
                            deferredMeasurementCache={this.cache}
                            rowHeight={({ index }) => this.cache.rowHeight({ index }) + (index === this.props.contributions.length - 1 ? 104 : 0)}
                            onRowsRendered={(args) => {
                              onRowsRendered(args);
                            }}
                          />
                        )
                      }
                    </InfiniteLoader>

                  </div>
                )
              }
            </AutoSizer>
          )
        }
      </WindowScroller>
    );
  }
}


const mapStateToProps = (state) => {
  const { filter } = state.ui.dashboard;
  let contributions = getAllContributions(state.contributions);
  if (filter) {
    contributions = contributions.filter(({ meta }) => meta.status === filter.id);
  }
  return {
    contributions,
    sources: state.sources.sources,
    jurisdictions: state.jurisdictions,
    animatingItems: state.ui.dashboard.animatingItems,
  };
};

export default connect(
  mapStateToProps,
  {
    createComment,
    setSectionStatus,
    setBulkStatus,
    clearAnimatingContributions,
  },
  null,
  { withRef: true },
)(DashboardResults);


DashboardResults.propTypes = {
  contributions: PropTypes.array,
  filter: PropTypes.object,
  getContributions: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  selected: PropTypes.object,
  toggleContribution: PropTypes.func.isRequired,
  setSectionStatus: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  jurisdictions: PropTypes.array.isRequired,
  statuses: PropTypes.object.isRequired,
  clearAnimatingContributions: PropTypes.func.isRequired,
  setBulkStatus: PropTypes.func.isRequired,
  animatingItems: PropTypes.object,
  noResultsDashboard: PropTypes.node.isRequired,
};

DashboardResults.defaultProps = {
  selected: {},
  contributions: [],
  filter: null,
  animatingItems: null,
};
